import { useEffect, useState } from "react";
import { useLoaderData, useLocation, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { orderActions } from "../store/orderSlice";
import ApplicationProcess from "./ApplicationProcess";
import FetchError from "./FetchError";
import classes from "./orderPage.module.css";
import ApplicationHome from "./startPage/ApplicationHome";
import { backendUrlStart } from "../Constants/Constants";

function OrderPage() {
  const [orderView, setOrderView] = useState("index");
  const [applicationStage, setApplicationStage] = useState(0);
  const [successfullFetch, setSuccessfullFetch] = useState(false);
  const [couldNotFetch, setCouldNotFetch] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [storeData, setStoreData] = useState([]);

  const submittedParam = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location.search);
  const paymentStatus = searchParams.get("status");
  const userEmail = searchParams.get("email");
  const customerCode = searchParams.get("customer_code");

  const arrivedLegally = paymentStatus && userEmail && customerCode;

  const storeDatas = useLoaderData();

  useEffect(() => {
    if (arrivedLegally) {
      setOrderView("startApplication");
      setApplicationStage(7);
    } else {
    }
  }, [arrivedLegally]);

  useEffect(() => {
    if (storeDatas.storeFetchErrorMessage) {
      setErrMessage(storeDatas.storeFetchErrorMessage);
      setCouldNotFetch(true);
      setSuccessfullFetch(false);
      dispatch(orderActions.setShopId(""));
      document.title = `店舗が見つかりません。`;
    } else {
      //went through

      let data = storeDatas.filter(
        (store) => store.shopCodeNumber === submittedParam.storeNumber
      );
      let storeName = data[0].shopName;
      document.title = `${storeName}- 申し込みフォーム | スカイセブンモバイル`;
      if (data.length > 0) {
        setStoreData(data);
        setSuccessfullFetch(true);
        setCouldNotFetch(false);
        let shopId = data[0].id;
        dispatch(orderActions.setShopCode(submittedParam.storeNumber));
        dispatch(orderActions.setShopId(shopId));
      } else {
        setSuccessfullFetch(false);
        setCouldNotFetch(true);
        document.title = `店舗が見つかりません。`;
      }
    }
  }, [storeDatas, dispatch, submittedParam.storeNumber]);

  const currentView = () => {
    setOrderView("startApplication");
  };
  const establishApplicationStage = (stageNumber) =>
    setApplicationStage(stageNumber);

  return (
    <>
      {successfullFetch && (
        <div className={classes.orderPageContainer}>
          {orderView === "index" && (
            <ApplicationHome
              viewControl={currentView}
              storeInfo={{ storeData, submittedParam }}
            />
          )}
          {orderView === "startApplication" && (
            <ApplicationProcess
              storeInfo={{ storeData, submittedParam }}
              applicationData={{
                applicationStage: applicationStage,
                setApplicationStage: establishApplicationStage,
              }}
            />
          )}
        </div>
      )}
      {couldNotFetch && <FetchError message={errMessage} />}
    </>
  );
}

export default OrderPage;

export const storeLoader = async ({ params }) => {
  try {
    const res = await fetch(backendUrlStart);
    return await res.json();
  } catch (error) {
    return {
      storeFetchErrorMessage:
        "申し訳ありません、ストアの取得中にエラーが発生しました。",
    };
  }
};
